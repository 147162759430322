import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Politykaprywatnosci = () => {
  return (
    <Layout>
      <SEO
        title="Polityka prywatności"
        description="Informacje o polityce prywatności."
      />
      <div className="container container--text">
        <h1>Polityka prywatności</h1>
        <br />
        <p>
          Questy – Wdrożenia i Wsparcie IT Sp. z o.o. to firma, która dokłada
          najwyższej staranności do ochrony prywatności. Pragniemy przekazać Ci
          dokładne informacje o działaniach podejmowanych na danych osobowych.
          Zależy nam na Twoim zaufaniu, dlatego stworzyliśmy Politykę
          Prywatności, abyś wiedział, w jakim zakresie działamy i jakich
          standardów przestrzegamy, aby zapewnić rzetelność i transparentność
          przetwarzania danych osobowych.
        </p>
        <p>
          Przed skorzystaniem z naszego systemu w jakiejkolwiek formie, zapoznaj
          się z pełną treścią niniejszej Polityki. Jednocześnie musisz wiedzieć,
          że korzystanie z systemu oznacza pełną akceptację procedur i
          standardów stosowanych przez Questy i wynikających z polityki.
        </p>
        <p>
          Jeżeli nie akceptujesz procedur wynikających z polityki, nie możesz
          korzystać z systemu. Pamiętaj, że w razie pytań lub wątpliwości,
          możesz się z nami skontaktować.
        </p>
        <p>
          Chcemy, aby polityka była dla Ciebie jasna i czytelna, dlatego na
          początek wyjaśniamy kilka pojęć:
        </p>

        <p>
          {" "}
          <strong> Dane osobowe </strong> — to wszelkie dane odbiorców
          definiowane zgodnie z RODO oraz Ustawą o Ochronie Danych Osobowych,
          które importujesz do systemu.
        </p>
        <p>
          <strong>Questy</strong> – Questy – Wdrożenia i Wsparcie IT Sp. z o.o.
          z siedzibą w Poznaniu, wpisana do rejestru przedsiębiorców Krajowego
          Rejestru Sądowego przez Sąd Rejonowy w Poznaniu – Nowe Miasto i Wilda
          w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestry Sądowego pod
          nr KRS: 0000403007.
        </p>
        <p>
          <strong> System </strong> – to program informatyczny dostępny online,
          dostarczany przez Questy, za pośrednictwem przeglądarek internetowych,
          działający w modelu Software as a Services. Do niego importujesz bazy
          danych zawierające adresy mailowe i za jego pośrednictwem wysyłasz
          mailing.
        </p>
        <p>
          <strong>Aplikacja mobilna</strong> - aplikacja pobrana na urządzenie
          mobilne użytkownika, współpracująca z Systemem.
        </p>
        <p>
          <strong> Wysyłka e-mail </strong> – jeśli to umożliwia nasz System.
          Wysyłką e-mail jest dokonanie przez Ciebie masowej wysyłki e-maila, a
          także wysyłka pojedynczej wiadomości e-mail.
        </p>
        <p>
          <strong> Odbiorcy </strong> – to osoby, których adresy mailowe
          importujesz do Systemu.
        </p>
        <p>
          <strong> Polityka </strong> – to dokument, który właśnie czytasz. Jest
          to zbiór zasad, reguł oraz naszych doświadczeń dotyczących
          Przetwarzania Danych Osobowych.
        </p>
        <p>
          <strong> Polityka Antyspamowa </strong> – przykładamy szczególną wagę
          do tego, żeby korzystanie z Systemu odbywało się zgodnie z przepisami
          powszechnie obowiązującego prawa oraz ogólnie akceptowalnymi zasadami
          Internetu.
        </p>
        <p>
          <strong> Przetwarzanie </strong> – to dokonywane przez nas operacje na
          Danych Osobowych. Przez Przetwarzanie rozumiemy czynności takie jak
          zbieranie, utrwalanie, przechowywanie, opracowywanie, zmienianie,
          udostępnianie i usuwanie Danych Osobowych, a zwłaszcza te operacje,
          które prowadzą do przeprowadzenia dla Ciebie skutecznej Kampanii.
        </p>
        <p>
          <strong> Regulamin </strong> – jest to dokument, w którym określiliśmy
          warunki korzystania z Systemu oraz Serwisu.
        </p>
        <p>
          <strong> Serwis </strong> – to strona internetowa dostępna pod adresem
          serwis.questy.pl. W momencie przekazania systemu zostanie utworzone
          dla Ciebie konto klienta. Zostaniesz o tym poinformowany na podany
          przez Ciebie adres e-mail. Serwis to inaczej Panel Klienta, poprzez
          który będziesz mógł się z nami kontaktować.
        </p>
        <p>
          <strong> Ustawa </strong> – to polska ustawa z dnia 29 sierpnia 1997
          roku o ochronie danych osobowych (tekst jednolity Dz. U. z 2014 r.,
          poz. 1182 ze zm.).
        </p>
        <p>
          <ol>
            <li>
              Serwis nie zbiera w sposób automatyczny żadnych informacji, z
              wyjątkiem informacji zawartych w plikach cookies.
            </li>
            <li>
              Polityka tworzy kompleksową całość i żadna z jej części nie
              powinna być przez Ciebie interpretowana w oderwaniu od
              pozostałych.
            </li>
            <li>
              Podczas przeglądania strony pl oraz stron produktowych, do których
              możesz za jej pośrednictwem się dostać, czy to jako Użytkownik
              Systemu, odbiorca naszego newslettera czy jako anonimowa osoba —
              nasze serwery automatycznie gromadzą informacje wysyłane przez
              Twoją przeglądarkę oraz te zawarte w logach systemowych.
              Informacje te mogą zawierać takie dane jak adresy email, adresy
              IP, typ przeglądarki, stronę internetową odwiedzaną bezpośrednio
              przed wejściem na nasze strony czy do Systemu oraz inne dane.
              Questy wykorzystuje te dane wyłącznie do zawarcia, zmiany lub
              rozwiązania umowy z Klientem, w celu zapewnienia jak najwyższej
              jakości świadczonych usług, a także w celach statystycznych i
              technicznych.
            </li>
            <li>
              Przed skorzystaniem z funkcjonalności Systemu i Serwisu możemy
              prosić Cię o podanie niektórych Danych Osobowych, przy czym nigdy
              nie prosimy Cię o podanie danych, które nie są nam niezbędne.
            </li>
            <li>
              W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
              cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
              cookies). Cookies „sesyjne” są plikami tymczasowymi, które
              przechowywane są w urządzeniu końcowym Użytkownika do czasu
              wylogowania, opuszczenia strony internetowej lub wyłączenia
              oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies
              przechowywane są w urządzeniu końcowym Użytkownika przez czas
              określony w parametrach plików cookies lub do czasu usunięcia ich
              przez Użytkownika.
            </li>
            <li>
              Podanie przez Ciebie Danych Osobowych jest zawsze dobrowolne.
              Pamiętaj jednak, że w przypadku niepodania Danych Osobowych, o
              które zostaniesz przez nas poproszony, nie uzyskasz dostępu do
              funkcjonalności Systemu lub Serwisu.
            </li>
            <li>
              W wielu przypadkach oprogramowanie służące do przeglądania stron
              internetowych (przeglądarka internetowa) domyślnie dopuszcza
              przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
              Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
              dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
              szczególności w taki sposób, aby blokować automatyczną obsługę
              plików cookies w ustawieniach przeglądarki internetowej bądź
              informować o ich każdorazowym zamieszczeniu w urządzeniu
              Użytkownika Serwisu. Szczegółowe informacje o możliwości i
              sposobach obsługi plików cookies dostępne są w ustawieniach
              oprogramowania (przeglądarki internetowej).
            </li>
            <li>
              Cały czas doskonalimy naszą stronę internetową, System oraz
              Serwis, dlatego w sposób automatyczny mogą być gromadzone różne
              informacje o osobach przeglądających System, w tym o Tobie.
            </li>
            <li>
              W tym celu możemy także wykorzystywać ciasteczka (pliki cookies).
            </li>
          </ol>
        </p>
        <br />
        <h3> Aplikacja mobilna </h3>
        <br />
        <p>
          <ol>
            <li>
              Jeśli korzystasz z aplikacji mobilnej to pamiętaj, że aplikacja
              może mieć dostęp do lokalizacji urządzenia.
            </li>
            <li>
              Dane o lokalizacji zbierane są w celu lokalizacji pracowników na
              mapie przez pracodawcę i są wysyłane na serwer, który wprowadzisz
              w ustawieniach aplikacji.
            </li>
            <li>
              Aplikacja pobiera informacje o lokalizacji w tle oraz w trakcie
              wykonywania zadania.
            </li>
            <li>
              Zgodę na dostęp do lokalizacji możesz zmienić w ustawieniach
              swojego urządzenia.
            </li>
          </ol>
        </p>
        <br />
        <h3> Poznajmy się </h3>
        <br />
        <p>
          <ol>
            <li>
              Aby nasza współpraca przebiegała płynnie musimy się nawzajem
              poznać. O nas wiesz już wszystko, a jeśli chcesz wiedzieć więcej
              skontaktuj się z nami korzystając z danych kontaktowych podanych
              na końcu Polityki.
            </li>
            <li>
              {" "}
              Chcemy Cię bliżej poznać, dlatego poprosimy Cię o podanie danych:
              <br /> a) podczas samodzielnego tworzenia wersji demo: nazwy
              firmy, adresu e-mail;
              <br /> b) podczas kontaktowania się z nami poprzez formularze
              dostępne na stronach: imienia, nazwiska, adresu e-mail, informacji
              o wielkości firmy, numeru telefonu;
              <br /> c) podczas dokonania pierwszej płatności lub momencie
              wyrażenia z Twojej chęci zainteresowania ofertą handlową, jeżeli
              reprezentujesz firmę: dane firmy, którą reprezentujesz, adres
              korespondencyjny, numer telefonu.{" "}
            </li>

            <li>
              {" "}
              Kiedy przekazujesz nam swoje Dane Osobowe w każdym z wyżej
              wymienionych podpunktów (a,b,c) stajemy się ich administratorem.
              Administratorem jest Questy – Wdrożenia i Wsparcie IT Sp. z o.o. z
              siedzibą w Poznaniu przy ul. Głogowskiej 31/33, wpisana do
              rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd
              Rejonowy w Poznaniu – Nowe Miasto i Wilda w Poznaniu, VIII Wydział
              Gospodarczy Krajowego Rejestry Sądowego pod nr KRS: 0000403007.{" "}
            </li>
            <li>
              {" "}
              Twoje dane są przez nas Przetwarzane wyłącznie za Twoją zgodą lub
              w przypadku, gdy zezwalają nam na to przepisy prawa.{" "}
            </li>
            <li>
              {" "}
              Przetwarzamy Twoje Dane Osobowe:
              <br /> a) w celu świadczenia usług związanych z udostępnianiem
              funkcjonalności Systemu, działalnością Serwisu oraz odpowiedniego
              zarządzania kontaktami w Questy;
              <br /> b) w celach marketingowych – przesyłania Ci naszych
              własnych informacji handlowych. Przed wysłaniem takich informacji
              zawsze pytamy się Ciebie o zgodę. Wystarczy, że podczas kontaktu
              poprzez formularz lub rejestracji zaznaczysz odpowiednie
              formularze;
              <br /> c) w celach statystycznych.
            </li>
          </ol>
        </p>
        <br />
        <br />
        <h3> Pamiętaj, że decyzja należy do Ciebie </h3>
        <p>
          <ol>
            <li>
              Pamiętaj, że to Ty decydujesz o zakresie i celu Przetwarzania. W
              każdej chwili możesz:
              <br /> a) kontaktować się z nami w celu uzyskania informacji, jaka
              jest treść przekazywanych przez Ciebie Danych Osobowych oraz jak
              dokładnie są one wykorzystywane;
              <br /> b) na bieżąco aktualizować oraz poprawiać Twoje Dane
              Osobowe;
              <br /> c) odwołać zgodę na Przetwarzanie, a także żądać usunięcia
              Twoich Danych Osobowych w całości lub części. Pamiętaj jednak, że
              może się to wiązać z utratą możliwości korzystania przez Ciebie z
              funkcjonalności Systemu i Serwisu.
            </li>
            <li>
              {" "}
              W wyjątkowych przypadkach możemy nie dostosować się do Twojej
              prośby dotyczącej usunięcia Danych Osobowych. Może to nastąpić w
              przypadku naruszenia przez Ciebie Regulaminu lub Polityki
              Antyspamowej, a także naruszenia przepisów powszechnie
              obowiązującego prawa. Zachowanie Danych Osobowych będzie miało
              wówczas na celu wyłącznie ustalenie okoliczności naruszeń lub
              ewentualne ustalenie odpowiedzialności za naruszenia.
            </li>
            <li>
              {" "}
              Zbierane zgodnie z Polityką dane to cenny materiał badawczy,
              dlatego możemy tworzyć i ujawniać anonimowe zestawienia oraz
              statystyczne dane dotyczące działania Serwisu. Zestawienia takie
              nie są jednak Danymi Osobowymi, a tym samym nie pozwalają na
              jakąkolwiek identyfikację Ciebie lub innych użytkowników.
            </li>
            <li>
              {" "}
              Dbamy o bezpieczeństwo Twoich Danych Osobowych, dlatego stosujemy
              bardzo wysokie zabezpieczenia oraz zachowujemy poufność wszelkich
              przekazanych informacji. Jednym z elementów naszych zabezpieczeń
              jest polityka bezpieczeństwa, którą przyjęliśmy oraz sumiennie
              stosujemy. Wypełniamy także wszystkie obowiązki spoczywające na
              nas jako na administratorze Danych Osobowych, a wynikające z
              przepisów powszechnie obowiązującego prawa, w szczególności zaś z
              Ustawy.
            </li>
          </ol>
        </p>
        <br />
        <br />
        <h3> O bezpieczeństwo musisz dbać również Ty </h3>
        <p>
          <ol>
            <li>
              {" "}
              Pamiętaj, że aby Twoje Dane Osobowe były bezpieczne, musimy dbać o
              nie razem. Dlatego powinieneś zawsze trzymać swoje dane logowania
              do Systemu lub Serwisu, zarówno hasło, jak i login, w poufności.
            </li>
            <li>
              {" "}
              Jeżeli dzielisz swoje stanowisko pracy (komputer) wspólnie z inną
              osobą, pamiętaj, aby zawsze po zakończeniu korzystania z Systemu
              lub Serwisu wylogować się.
            </li>
            <li>
              {" "}
              Model SaaS, nie wymaga od Ciebie instalowania na komputerze
              użytkownika Systemu, jest on dostępny za pośrednictwem
              przeglądarki internetowej. Jako użytkownik nie musisz się martwić
              instalacją, utrzymaniem czy aktualizacją oprogramowania. To nasze
              zadanie.
            </li>
            <li>
              {" "}
              Pamiętaj jednak, że to Ty korzystając z Systemu wprowadzasz do
              niego szereg informacji, w tym Dane Osobowe.
            </li>
            <li>
              W związku z dostępem do danych osobowych przekazywanym przez
              Ciebie, dostawca, czyli Questy staje się podmiotem
              przetwarzającym.
            </li>
            <li>
              Podmiot Przetwarzający to osoba bądź instytucja, która przetwarza
              dane osobowe na polecenie innej osoby bądź instytucji.
            </li>
            <li>
              Użytkownik Systemu, staje się więc administratorem danych, które
              wprowadza do Systemu.
            </li>
            <li>
              Jako administrator danych powinieneś spełniać obowiązki
              zdefiniowane zgodnie z RODO oraz Ustawą o Ochronie Danych
              Osobowych.
            </li>
            <li>
              {" "}
              RODO wymaga, aby przekazywanie Danych Osobowych odbywało się na
              podstawie ważnej umowy pomiędzy administratorem a podmiotem
              przetwarzającym.
            </li>
            <li>
              {" "}
              Jako podmiot przetwarzający Questy zobowiązuje się do:
              <br /> a) zachowania w pełnej tajemnicy przekazanych danych
              osobowych i dopuszczenia do przetwarzania danych tylko osoby
              upoważnione,
              <br /> b) usunięcia lub zwrotu danych osobowych administratorowi
              po zakończeniu świadczenia usług,
              <br /> c) przetwarzania danych tylko na polecenie administratora,
              <br /> d) zapewnienia bezpieczeństwa danych, najlepszymi
              dostępnymi w danym czasie środkami,
              <br /> e) nieprzekazywanie danych osobowych innym podmiotom bez
              zgody administratora.
            </li>
          </ol>
        </p>
        <br />
        <br />
        <h3> Ciasteczka </h3>
        <p>
          <ol>
            <li>
              {" "}
              Chcemy udoskonalać nasz Serwis, ułatwić korzystanie z niego oraz
              jak najlepiej dopasować go do Twoich potrzeb, dlatego poza
              dobrowolnie podanymi przez Ciebie informacjami korzystamy z
              ciasteczek (pliki cookies), tj. plików zawierających dane
              informatyczne, które zapisywane są na używanym przez Ciebie
              urządzeniu końcowym tak, że możliwe jest ich odczytanie po
              ponownym połączeniu z Serwisem.{" "}
            </li>
            <li>
              {" "}
              Ciasteczka mają służyć Tobie i nam. Ich celem jest dostosowanie
              zawartości Serwisu do Twoich indywidualnych preferencji,
              optymalizacji korzystania z Serwisu oraz jego indywidualizacji.
              Dzięki ciasteczkom możliwe jest także podtrzymanie sesji logowania
              w Systemie i Serwisie. My zaś możemy dzięki nim możemy tworzyć
              statystyki dotyczące korzystania z Systemy i Serwisu, co pozwala
              na ich ciągłe ulepszanie.{" "}
            </li>
            <li>
              {" "}
              Ciasteczka nie powodują żadnych zmian w konfiguracji używanego
              przez Ciebie urządzenia końcowego. Informacje te wykorzystywane są
              jedynie dla tworzenia statystyk funkcjonowania Serwisu i Systemu.
              Mogą być także udostępniane podmiotom zewnętrznym, jednak jedynie
              w sytuacji, gdy wymagają tego przepisy prawa lub w przypadku
              powierzenia przetwarzania informacji o ciasteczkach podmiotom
              zewnętrznym. W naszym Serwisie wykorzystujemy ciasteczka służące
              do analizy aktywności w naszych witrynach.{" "}
            </li>
            <li>
              {" "}
              Pamiętaj, że zawsze możesz zrezygnować z ciasteczek. Niezależnie
              od tego, jakiej przeglądarki internetowej używasz, możesz
              skorzystać z opcji wyłączenia ciasteczek zarówno dla konkretnej
              witryny, jak i wszystkich odwiedzanych przez Ciebie stron
              internetowych. Informację o tym, jak skorzystać z powyższej opcji
              możesz znaleźć u producenta stosowanej przez Ciebie przeglądarki.
              Jeżeli jednak zdecydujesz się pozostać w Systemie i Serwisie bez
              wyłączenia ciasteczek, wyrażasz zgodę na ich pobieranie.{" "}
            </li>
          </ol>
        </p>
        <br />
        <br />
        <h4> Jeżeli masz pytania – skontaktuj się z nami</h4>
        <p>
          Jeżeli masz jakiekolwiek pytania skontaktuj się z nami:
          <br /> a) na adres e-mail: kontakt@questy.pl
          <br /> b) bezpośrednio do przydzielonego Tobie doradcy, którego dane
          kontaktowe na pewno otrzymasz już w pierwszym kontakcie z naszą firmą.
          <br /> c) za pomocą formularza dostępnego na stronie
          https://questy.pl/kontakt
          <br /> d) pisemnie, na adres:
          <br /> Questy – Wdrożenia i Wsparcie IT Sp. z o.o.
          <br /> Głogowska 31/33
          <br /> Poznań
        </p>
        <p>
          {" "}
          Questy zastrzega sobie prawo wprowadzania zmian lub uzupełnień do
          niniejszej Polityki Prywatności. Wszelkie tego rodzaju zmiany będą
          publikowane na stronie www.questy.pl w zakładce „Polityka
          Prywatności”, w związku z czym zalecamy bieżące odwiedzanie naszej
          strony internetowej i monitorowanie aktualności zasad korzystania z
          Systemu i Serwisu przez wszystkich naszych użytkowników.
        </p>
      </div>
    </Layout>
  );
};

export default Politykaprywatnosci;
